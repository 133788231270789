@import url("https://fonts.googleapis.com/css2?family=Inter:wght@400;600&display=swap");

body {
	margin: 0;
	padding: 0;
	font-family: "Inter", sans-serif;
	background: linear-gradient(135deg, #000000 0%, #1a1a1a 100%);
	color: #ffffff;
	min-height: 100vh;
}

.App {
	text-align: center;
}

.App-logo {
	height: 40vmin;
	pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
	.App-logo {
		animation: App-logo-spin infinite 20s linear;
	}
}

h2 {
	font-size: 3rem;
	margin-bottom: 2rem;
}

.App-link {
	color: #61dafb;
}

@keyframes App-logo-spin {
	from {
		transform: rotate(0deg);
	}
	to {
		transform: rotate(360deg);
	}
}

.App-header {
	min-height: 100vh;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	font-size: calc(10px + 2vmin);
}

.button {
	background-color: #3a3a3a;
	color: #ffffff;
	border: none;
	padding: 12px 24px;
	font-size: 16px;
	font-weight: 600;
	border-radius: 8px;
	cursor: pointer;
	transition: background-color 0.3s ease;
}

.button:hover {
	background-color: #4a4a4a;
}

.dashboard-title {
	font-weight: 600;
}
